<template>
  <div class="responsive-video">
    <div class="video-container">
      <video
        autoplay
        muted
        loop
        ref="videoPlayer"
        :src="videoSrc"
        type="video/mp4"
        @loadedmetadata="calculateAspectRatio"
        playsinline
      ></video>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      videoSrc: "/videos/Video_loop2.mp4",
      aspectRatio: 16 / 9, // Change this to match your video's aspect ratio
    };
  },
  mounted() {
    window.addEventListener("resize", this.calculateAspectRatio);
    this.calculateAspectRatio();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.calculateAspectRatio);
  },
  methods: {
    calculateAspectRatio() {
      const container = this.$el.querySelector(".video-container");
      const video = this.$refs.videoPlayer;

      const containerWidth = container.offsetWidth;
      const newHeight = containerWidth / this.aspectRatio;

      video.style.width = "100%";
      video.style.height = `${newHeight}px`;
    },
  },
};
</script>

<style scoped>
.responsive-video {
  width: 100%;
  max-width: 100vw;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio - adjust this value to match your video's aspect ratio */
  overflow: hidden;
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
